// import logo from './logo.svg';
import './App.css';

import { Heading } from '@chakra-ui/react'
import Header from './components/Header'

function App() {
  return (
    <div className="App">
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>Hello from V2</h1>
      </header> */}

      <Header />

      <Heading size='lg' fontSize='50px'>
        I'm overriding this heading
      </Heading>
    </div>
  );
}

export default App;
